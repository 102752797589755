import {createRoot} from "react-dom/client";
import {Router} from "react-router-dom";
import {initSentry} from "@skbkontur/hotel-sentry";
import {createBrowserHistory} from "history";
import App from "./components/App";
import {BrowserHelper} from "./helpers/BrowserHelper";

module?.hot?.accept();

const history = createBrowserHistory();

initSentry({
    options: {
        dsn: "https://e4f2ddb21207410aa0af246214fa95d0@sentry.kontur.host/301"
    },
    reactRouterOptions: {history}
});

const container = document.getElementById("root");
if (!BrowserHelper.isIE()) {
    const root = createRoot(container);
    root.render(
        <Router history={history}>
            <App/>
        </Router>
    );
}
export {WidgetType} from "./widget/settings/WidgetType";