import {IIconProps, IconTypes} from "./Icon";
import {LogoWhatsappIcon16Regular} from "@skbkontur/icons/LogoWhatsappIcon16Regular";

type WhatsUpIconTypes = IconTypes.Regular16;

interface IWhatsAppIconProps extends IIconProps {
    type: WhatsUpIconTypes;
}

const WhatsAppIcon = ({type, ...iconProps}: IWhatsAppIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <LogoWhatsappIcon16Regular {...iconProps} />;
    }
};
WhatsAppIcon.displayName = "WhatsAppIcon";
export default WhatsAppIcon;
