import {IIconProps, IconTypes} from "./Icon";
import {LogoVkIcon16Solid} from "@skbkontur/icons/LogoVkIcon16Solid";

type VkIconTypes = IconTypes.Solid16;

interface IVkIconProps extends IIconProps {
    type: VkIconTypes;
}

const VkIcon = ({type, ...iconProps}: IVkIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <LogoVkIcon16Solid {...iconProps} />;
    }
};
VkIcon.displayName = "VkIcon";
export default VkIcon;
