import {useDispatch} from "react-redux";
import {useTranslation} from "@skbkontur/i18n";
import {useMount} from "@skbkontur/hotel-hooks/react";
import PaymentByLinkPagePaymentSource from "../PaymentSource/PaymentByLinkPagePaymentSource";
import PaymentByLinkPageError from "../Error/PaymentByLinkPageError";
import WhiteSpinner from "../../../components/WhiteSpinner/WhiteSpinner";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import {PaymentLinkStatus, PaymentSystem} from "../../../data/Payment";
import {getPaymentLink} from "../../../store/payment/paymentActionCreators";
import styles from "./PaymentByLinkPageByStatus.scss";

interface IPaymentLinkByStatusPageState {
    url: string;
    paymentSource: PaymentSystem;
    linkStatus: PaymentLinkStatus;
}

interface IPaymentLinkByStatusPageProps {
    paymentId: string;
}

const PaymentByLinkPageByStatus = (props: IPaymentLinkByStatusPageProps) => {
    const {paymentId} = props;

    const {t, lng} = useTranslation(TranslationNamespaces.BookingModuleOnlinePayment);
    const dispatch = useDispatch();

    const [onlinePaymentState, setOnlinePaymentState] = React.useState<IPaymentLinkByStatusPageState>({
        url: null,
        paymentSource: null,
        linkStatus: null,
    });
    const {url, paymentSource, linkStatus} = onlinePaymentState;

    useMount(async () => {
        /* eslint-disable @typescript-eslint/await-thenable */
        // @ts-expect-error Need to use new Redux
        const {response: {url, paymentSource, linkStatus}} = await dispatch(getPaymentLink(lng, paymentId));
        setOnlinePaymentState({url, paymentSource, linkStatus});
    });

    return (
        <div className={styles.root}>
            {linkStatus ? (
                <>
                    {linkStatus === PaymentLinkStatus.Active
                        ? <PaymentByLinkPagePaymentSource url={url} paymentSource={paymentSource}/>
                        : (
                            <PaymentByLinkPageError
                                errorText={
                                    linkStatus === PaymentLinkStatus.Inactive
                                        ? t("error.brokenLink", {raw: true})
                                        : t("error.expiredLink", {raw: true})
                                }
                            />
                        )}
                </>
            ) : <WhiteSpinner caption={t("loading")}/>}
        </div>
    );
};

export default PaymentByLinkPageByStatus;
PaymentByLinkPageByStatus.displayName = "PaymentByLinkPageByStatus";
