import {IIconProps, IconTypes} from "./Icon";
import {LogoViberIcon16Regular} from "@skbkontur/icons/LogoViberIcon16Regular";

type ViberIconTypes = IconTypes.Regular16;

interface IViberIconProps extends IIconProps {
    type: ViberIconTypes;
}

const ViberIcon = ({type, ...iconProps}: IViberIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <LogoViberIcon16Regular {...iconProps} />;
    }
};
ViberIcon.displayName = "ViberIcon";
export default ViberIcon;
