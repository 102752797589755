import {useSelector} from "react-redux";
import styles from "./HotelContacts.scss";
import {AddressHelper} from "../../../helpers/AddressHelper";
import {ContactType, IContact} from "../../../data/HotelInfo";
import {IAppState} from "../../../store/AppState";
import HotelPageMaxWidth from "../HotelPageMaxWidth";
import {useTranslation} from "@skbkontur/i18n";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import HotelContactsItem from "./HotelContactsItem";
import {
    IconTypes,
    WhatsAppIcon,
    TelegramIcon,
    VkIcon,
    ViberIcon,
    PhoneIcon,
    MapPinIcon
} from "@skbkontur/hotel-components/icon";

const getContactsByType = (contacts: IContact[], type: ContactType): string[] => (
    contacts?.length
        ? contacts.filter(c => c.type === type).map(p => p.value)
        : []
);

const HotelContacts = () => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);
    const {address, contacts} = useSelector((state: IAppState) => state.hotelInfo.info);

    return (
        <HotelPageMaxWidth>
            <span className={styles.title}>{t("contacts")}</span>
            <div className={styles.container}>
                <HotelContactsItem
                    values={[AddressHelper.getUnbreakableAddress(address)]}
                    icon={<MapPinIcon type={IconTypes.Solid16} />}
                    type={ContactType.Address}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Phone)}
                    icon={<PhoneIcon type={IconTypes.Solid16} />}
                    type={ContactType.Phone}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Email)}
                    icon={<span className={styles.icon}>@</span>}
                    type={ContactType.Email}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Whatsapp)}
                    icon={<WhatsAppIcon type={IconTypes.Regular16} />}
                    type={ContactType.Whatsapp}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Telegram)}
                    icon={<TelegramIcon type={IconTypes.Regular16} />}
                    type={ContactType.Telegram}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Vk)}
                    icon={<VkIcon type={IconTypes.Solid16} />}
                    type={ContactType.Vk}
                />
                <HotelContactsItem
                    values={getContactsByType(contacts, ContactType.Viber)}
                    icon={<ViberIcon type={IconTypes.Regular16} />}
                    type={ContactType.Viber}
                />
            </div>
        </HotelPageMaxWidth>
    );
};
HotelContacts.displayName = "HotelContacts";
export default HotelContacts;
