import {IIconProps, IconTypes} from "./Icon";
import {TechPhoneClassicIcon16Solid} from "@skbkontur/icons/TechPhoneClassicIcon16Solid";

type PhoneIconTypes = IconTypes.Solid16;

interface IPhoneIconProps extends IIconProps {
    type: PhoneIconTypes;
}

const PhoneIcon = ({type, ...iconProps}: IPhoneIconProps) => {
    switch (type) {
        case IconTypes.Solid16:
            return <TechPhoneClassicIcon16Solid {...iconProps} />;
    }
};
PhoneIcon.displayName = "PhoneIcon";
export default PhoneIcon;
