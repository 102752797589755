import {Link} from "@skbkontur/react-ui";
import {useTranslation} from "@skbkontur/i18n";
import {Ellipsis} from "@skbkontur/hotel-components/ellipsis";
import {IHotelSearchModel} from "../../../api/hotelSearch";
import {TranslationNamespaces} from "../../../constants/TranslationNamespaces";
import PrimaryButton from "../../../components/PrimaryButton/PrimaryButton";
import styles from "./HotelItem.scss";

interface IHotelItemProps {
    hotel: IHotelSearchModel;
    onClick: () => void;
}

export const HotelItem = ({hotel, onClick}: IHotelItemProps) => {
    const {t} = useTranslation(TranslationNamespaces.BookingModule);

    const imageStyle = hotel.thumbnailUrl
        ? {style: {backgroundImage: `url(${hotel.thumbnailUrl})`}}
        : {};

    return (
        <div key={hotel.alias} className={styles.item}>
            <div className={styles.itemImage}>
                <Link onClick={onClick}>
                    <div className={styles.itemImageSrc} {...imageStyle}/>
                </Link>
            </div>
            <div className={styles.itemContent}>
                <div className={styles.itemContentPart}>
                    <Ellipsis className={styles.itemContentName}>
                        {hotel.name}
                    </Ellipsis>
                    {hotel.address && (
                        <Ellipsis className={styles.itemContentAddress} lineClamp={2}>
                            {hotel.address}
                        </Ellipsis>
                    )}
                </div>
                {hotel.description && (
                    <div className={styles.itemContentPart}>
                        <Ellipsis className={styles.itemContentDesc} lineClamp={3}>
                            {hotel.description}
                        </Ellipsis>
                    </div>
                )}
                <div className={styles.itemContentPanel}>
                    <PrimaryButton size="large" width={160} onClick={onClick}>
                        {t("Buttons.look")}
                    </PrimaryButton>
                </div>
            </div>
        </div>
    );
};
