import styles from "./PaymentByLinkPage.scss";
import {RouteComponentProps, useHistory} from "react-router";
import {useMount, useSearchParams} from "@skbkontur/hotel-hooks/react";
import PaymentByLinkPageByStatus from "./PageByStatus/PaymentByLinkPageByStatus";

interface IPaymentByLinkPageProps {
    id: string;
}

interface IPaymentParams {
    fromHotel: boolean;
}

const PaymentByLinkPage = (props: RouteComponentProps<IPaymentByLinkPageProps>) => {
    const [isFromHotel, setIsFromHotel] = React.useState<boolean>(false);

    const history = useHistory();
    const paymentId = props.match.params.id;

    const {fromHotel} = useSearchParams<IPaymentParams>();
    const fromHotelRef = React.useRef(fromHotel);

    useMount(() => {
        if (fromHotelRef.current) {
            history.replace(paymentId);
            setIsFromHotel(true);
        }
    });

    return (
        <div className={styles.container}>
            {isFromHotel
                ? <PaymentByLinkPageByStatus paymentId={paymentId} />
                : null}
        </div>
    );
};

export default PaymentByLinkPage;
PaymentByLinkPage.displayName = "PaymentByLinkPage";
