import {IIconProps, IconTypes} from "./Icon";
import {LogoTelegramIcon16Regular} from "@skbkontur/icons/LogoTelegramIcon16Regular";

type CardIconTypes = IconTypes.Regular16;

interface ICardIconProps extends IIconProps {
    type: CardIconTypes;
}

const TelegramIcon = ({type, ...iconProps}: ICardIconProps) => {
    switch (type) {
        case IconTypes.Regular16:
            return <LogoTelegramIcon16Regular {...iconProps} />;
    }
};
TelegramIcon.displayName = "TelegramIcon";
export default TelegramIcon;
